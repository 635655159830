import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Theme } from '@material-ui/core';

import MainLayout from '../../layout/MainLayout';
import Panels from '../../components/Panel/panels';
import { GlobalStateType } from '../../redux/rootTypes';
import { listPanelAction } from '../../redux/panels/actions';
import useOnlineDispatch from '../../components/OnlineDispatch';
import THINK_THIN_DATA from '../../constants/staticPanels';
import ValidateSubscriptionComponent from '../../components/BillingStatus/validateBillingComponent';

const useStyle = makeStyles<Theme>((theme) => ({
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center' /* Center the image */,
        minHeight: 'calc(100vh - 200px)',
        height: 'auto',
        width: '100vw',

        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
}));

const Home = () => {
    const onlineDispatch = useOnlineDispatch();
    const classes = useStyle();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { panels, network } = useSelector((state: GlobalStateType) => state);
    useEffect(() => {
        onlineDispatch(listPanelAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network]);

    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid container justify="center" alignItems="flex-start">
                    <ValidateSubscriptionComponent>
                        <Panels panels={THINK_THIN_DATA} />{' '}                     
                    </ValidateSubscriptionComponent>
                    {/* <Panels panels={panels?.data} />{' '} */}
                </Grid>
            </div>
        </MainLayout>
    );
};

export default Home;
