import {
    Button,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import DrawerIcon from '../../icons/DrawerIcon';
import { GlobalStateType } from '../../redux/rootTypes';
import InternationalizationButton from '../InternationalizationButton';
import SideMenu from '../SideMenu';
import { showInfoClicked, toggleLeftSideMenu } from '../../redux/app/actions';
import HarmonizeCounter from '../InfoPanel/HarmonizeCounter';
import useOnlineDispatch from '../OnlineDispatch';
import { logoutAction } from '../../redux/user/actions';
import { ReactComponent as SignOutIcon } from '../../assets/svg_icons/sign-out-icon.svg';
import logoHeader from '../../assets/logoMobile.png';

const useStyle = makeStyles<Theme>((theme) => ({
    rootStyles: {
        backgroundColor: '#0468BF',

        // height: '200px',
        overflow: 'hidden',
        paddingBottom: '10px',
    },
    Grid: {
        display: 'flex',
        height: '100%',
    },
    rightGrid: {
        // marginTop: '-46.273193359375px',
        [theme.breakpoints.down('sm')]: {
            background: 'none',
            // height: '200px',
        },
        background:
            "transparent url('assets/layout/Header%20Background.svg') 0% 0% no-repeat padding-box",
        opacity: 1,
        mixBlendMode: 'normal',
    },
    logo: {
        marginTop: '30px',
        width: '321px',
        height: '157px',
        background: "transparent url('assets/layout/Logo.png') 0% 0% no-repeat padding-box",
        opacity: 1,
    },
    drawer: {
        marginTop: '34px',
        marginLeft: '38px',
        width: '24px',
        height: '22px',
        [theme.breakpoints.down(800)]: {
            marginTop: '84px',
            marginLeft: '20px',
        },
    },
    logout: {
        marginTop: '34px',
        marginLeft: '38px',
        [theme.breakpoints.down(800)]: {
            marginTop: '45px',
            marginLeft: '20px',
            paddingBottom: '25px'
        }
    },
    content: {
    marginTop: '40.273193359375px',
},
    internationalization: {
    marginTop: '84px',
    [theme.breakpoints.down(800)]: {
        marginTop: '80px',
        marginRight: '20px',
    },
},
    text: {
    color: 'white',
    marginTop: '10px',
    fontSize: 30,
    fontFamily: ['Poppins', 'regular'].join(','),
    textAlign: 'end',
},
    textActivity: {
    color: 'white',
    marginTop: '-5px',
    fontSize: 24,
    fontFamily: ['Poppins', 'regular'].join(','),
},
    timer: {
    color: 'white',
    marginTop: '-5px',
    fontSize: 14,
    fontFamily: ['Poppins', 'regular'].join(','),
},
    textSubtitle: {
    color: 'white',
    fontSize: 12,
    marginLeft: '20px',

    paddingBottom: '15px',
    fontFamily: ['Poppins', 'regular'].join(','),
    textAlign: 'end',
    [theme.breakpoints.down(800)]: {
        paddingBottom: '0 px',
    },
},
    textContainer: {
    // height: '60px',
    // [theme.breakpoints.down(800)]: {
    //     height: '150px',
    // },
},
    infoToggle: {
    fontSize: '12px',
    height: '25px',
    width: '120px',
    borderRadius: '13px',
    backgroundColor: 'white',
    color: 'black',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: 'white',
        boxShadow: 'none',
    },
    [theme.breakpoints.down(800)]: {
        marginLeft: '20px',
    },
},
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deleteCookie(name: any) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Header = ({ setHeaderText }: { setHeaderText: any }) => {
    const classes = useStyle();
    const { t } = useTranslation(['header']);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    const history = useHistory();
    const dispatch = useDispatch();
    const { drawerIsOpen, internationalizationMenuIsOpen, language, isShowingInfo } = useSelector(
        (state: GlobalStateType) => state.header,
    );
    const { activity } = useSelector((state: GlobalStateType) => state.panels);
    const onlineDispatch = useOnlineDispatch();

    const handleLogout = () => {
        deleteCookie('ScanImage');
        localStorage.removeItem('user');
        onlineDispatch(logoutAction());
    };
    const renderSpecialMovilHeader = () => (
        <>
            {/* <Grid item container xs={2} sm={2}>
                <IconButton
                    onClick={() => dispatch(toggleLeftSideMenu(drawerIsOpen))}
                    className={classes.drawer}
                >
                    <DrawerIcon />
                </IconButton>

                <SideMenu isOpen={drawerIsOpen} />
            </Grid> */}
            <Grid item container xs={3} sm={3}>
                &nbsp;
            </Grid>
            <Grid item container xs={6} sm={6}>
                <img src={logoHeader} alt="" className='mobileHeaderLogo' />
            </Grid>
            <Grid item container xs={3} sm={3}>
                &nbsp;
            </Grid>
            {/* <Grid item container xs={2} sm={2}>
                <IconButton
                    onClick={handleLogout}
                    className={classes?.logout}
                >
                    <SignOutIcon />
                </IconButton>
            </Grid> */}
            <Grid
                item
                xs={11}
                sm={11}
                className={classes.logout}
                direction="row"
                container
                justify="flex-end"
            >
                <Button variant="contained" color="primary" onClick={handleLogout} startIcon={<SignOutIcon />} >
                    Logout
                </Button>
            </Grid>
            {/* <Grid
                item
                container
                xs={8}
                sm={8}
                className={classes.internationalization}
                justify="flex-end"
            >
                <InternationalizationButton
                    isOpen={internationalizationMenuIsOpen}
                    language={language}
                />
            </Grid> */}
        </>
    );

    return (
        <Grid container direction="row" className={classes.rootStyles} wrap="wrap">
            <Grid item container xs={12} sm={12} className={classes.rightGrid} justify="flex-start">
                <Grid container justify="space-between">
                    {renderSpecialMovilHeader()}
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    spacing={1}
                    justify={isMobile ? 'flex-end' : 'center'}
                    alignContent="flex-start"
                    alignItems="center"
                    className={classes.textContainer}
                >
                    <Grid item xs={12}>
                        <Typography className={classes.text} variant="h1">
                            <>
                                {setHeaderText()}
                                {/* {history.location.pathname
                                    .split('/')
                                    .includes('harmonizing-screen') && <HarmonizeCounter />} */}
                            </>
                        </Typography>

                        <>
                            {/* {setHeaderText()} */}
                            {history.location.pathname
                                .split('/')
                                .includes('harmonizing-screen') && <HarmonizeCounter />}
                        </>

                    </Grid>
                    <Grid item xs={12}>
                        {' '}
                        {history.location.pathname.split('/').includes('panel-info') && (
                            <>
                                <Typography
                                    className={classes.textSubtitle}
                                    variant="caption"
                                    paragraph
                                >
                                    {activity?.name}
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} container justify="flex-start">
                {history.location.pathname.split('/').includes('panel-info') && (
                    <Button
                        disableFocusRipple
                        disableRipple
                        className={classes.infoToggle}
                        onClick={() => dispatch(showInfoClicked())}
                        endIcon={
                            isShowingInfo ? (
                                <ArrowBackIosIcon style={{ color: '#ffe000', fontSize: '14px' }} />
                            ) : (
                                <ArrowForwardIosIcon
                                    style={{ color: '#ffe000', fontSize: '14px' }}
                                />
                            )
                        }
                    >
                        {' '}
                        {isShowingInfo ? (
                            <Typography variant="caption">
                                {t(`header:panel-info.hide-info`)}
                            </Typography>
                        ) : (
                            <Typography variant="caption">
                                {t(`header:panel-info.more-info`)}
                            </Typography>
                        )}{' '}
                    </Button>
                )}
            </Grid> */}
        </Grid>
    );
};

export default Header;
