import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { GlobalStateType } from '../../redux/rootTypes';

import Background from './assets/bg.png';
import CaptchaLoader from './CaptchaLoader';

const IGNORED_PATH: string[] = ['/signed'];

function AuthWrapper(props: { children: React.ReactNode }) {
    const history = useHistory();
    const { children } = props;
    const [showPage, setShowPage] = useState(false);
    const user = useSelector((state: GlobalStateType) => state.user);

    useEffect(() => {
        if (user?.sub) {
            window.location.href = '/home';
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    const isIgnoredPath = (): boolean => {
        return IGNORED_PATH.includes(history.location.pathname);
    };

    return (
        <div
            style={{
                minHeight: '100vh',
                height: 'auto',
                backgroundImage: `url(${Background})`,
                backgroundSize: 'cover',
            }}
        >
            {((showPage || isIgnoredPath()) && children) || (
                <CaptchaLoader setShowPage={setShowPage} />
            )}
        </div>
    );
}

export default AuthWrapper;
