import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

interface BackgroundComponenteType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mediaFile: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaReady: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPause: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPlay: any;
    playing: boolean;
}

const Background = ({
    handleError,
    mediaFile,
    playing,
    handleMediaReady,
    handleMediaPause,
    handleMediaPlay,
}: BackgroundComponenteType) => {
    const onReady = () => {
        // eslint-disable-next-line no-console
        console.log('background ready to play...');
        handleMediaReady('background');
    };
    // video load fix
    const [backAudioFile, setBackAudioFile] = useState(mediaFile?.blob || mediaFile?.uri);
    useEffect(() => {
        setBackAudioFile(mediaFile?.blob || mediaFile?.uri);
    }, [mediaFile])

    useEffect(() => {
        const handleAudioError = (e: any) => {
            console.error('Error with audio playback:', e);
        };

        if (backAudioFile) {
            const audioElement = new Audio(backAudioFile);
            audioElement.addEventListener('error', handleAudioError);

            return () => {
                audioElement.removeEventListener('error', handleAudioError);
            };
        }
        return backAudioFile
    }, [backAudioFile]);

    return (
        <>
            {mediaFile && ( // video load fix  
                <ReactPlayer
                    loop
                    // video load fix
                    url={backAudioFile}
                    // url={mediaFile.uri}
                    // "assets/videos/WH_BackingTrack.mp3"
                    playing={playing}
                    onReady={onReady}
                    onPlay={() => {
                        // eslint-disable-next-line no-console
                        console.log('background on play..');
                        handleMediaPlay();
                    }}
                    playsinline
                    height={0}
                    style={{ zIndex: -1, position: 'absolute' }}
                    onPause={() => {
                        // eslint-disable-next-line no-console
                        console.log('background paused ...');
                        handleMediaPause();
                    }}
                    onError={(e) => {
                        // video load fix
                        setBackAudioFile(mediaFile?.uri);
                        handleError(e)
                    }}
                />
            )}
        </>
    );
};
export default Background;
