import { Grid, makeStyles, Theme, Typography, Select, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BigButton from '../utils/Button/BigButton';
import panelData from '../../i18n/en/infoPanel.json';
import { changeHarmonizeTime } from '../../redux/panels/actions';

interface PanelData {
    key: string,
    glassPlaceholder: string;
};

const useStyle = makeStyles<Theme>((theme) => ({
    infoName: {
        margin: '30px',
        fontWeight: 900,
        color: '#0468BF',
        fontSize: 36,
    },
    infoDescription: {
        fontFamily: ['Poppins', 'regular'].join(','),
        marginBottom: '30px',
        color: '#383838',
        fontSize: '16px',
    },
    dropdown: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#f0f5fd",
    },
    select: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: theme.spacing(3),

            },
        },
        '& .MuiSelect-select': {
            color: 'white',
            backgroundColor: "#00BBFF",
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: "#00BBFF",
        },
        '& .MuiSelect-icon': {
            color: 'white',
        },
        '& .MuiListItem-root': {
            color: 'white',
        },
        '& .MuiList-root': {
            backgroundColor: "#00BBFF",
        },
    },
}));

const InfoPanel = ({
    panel,
    validRedirectOnClick,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    panel: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validRedirectOnClick: any;
}) => {
    const classes = useStyle();
    const { t } = useTranslation(['infoPanel']);
    const [selectedDuration, setSelectedDuration] = useState<number | null>(null); // Default to 3 minutes
    const [placeholderText, setPlaceholderText] = useState<string>(panelData?.[3]?.glassPlaceholder || '');
    const [buttonDisabled, setButtonDisabled] = useState(true); // Initially disable the button
    const dispatch = useDispatch();
    
    const handleChangeDuration = (event: React.ChangeEvent<{ value: unknown }>) => {
        const duration = event.target.value as number;
        console.log("Dropdown value changed:", event.target.value);
        setSelectedDuration(duration);
        if (duration === 3) {
            dispatch(changeHarmonizeTime({harmonizeTime:180000}));
            const placeholder = panelData?.['3']?.glassPlaceholder;
            setPlaceholderText(placeholder);
        } else {
            dispatch(changeHarmonizeTime({harmonizeTime:60000}));
            const placeholder = panelData?.['1']?.glassPlaceholder;
            setPlaceholderText(placeholder);
        }
        setButtonDisabled(false); // Enable the button when a duration is selected
        console.log(" after Button disabled:", buttonDisabled);
    };
    return (
        <Grid
            item
            container
            xs={6}
            sm={6}
            justify="center"
            alignContent="center"
            alignItems="center"
            direction="column"
            style={{ marginTop: -500 }}
        >
            <Grid item>
                <Grid container>
                    <Grid item>
                        <img
                            src={`../assets/menu/new_${panel?.logo}.png`}
                            height={90}
                            alt={panel?.name}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" align="left" className={classes.infoName}>
                            {panel?.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography
                    variant="body1"
                    align="left"
                    paragraph
                    className={classes.infoDescription}
                >
                    {panel?.description}
                    {/* {placeholderText} */}
                </Typography>
            </Grid>
            {panel && (
                <Grid container spacing={2} justify="center" direction="column" alignItems="center">
                    <Grid item className={classes.dropdown}>
                        <Select
                            value={selectedDuration || ''}
                            onChange={handleChangeDuration}
                            variant="outlined"
                            fullWidth
                            className={classes.select}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Please select duration</MenuItem>
                            <MenuItem value={3}>3 min video</MenuItem>
                            <MenuItem value={1}>1 min video</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item>
                        {' '}
                        <BigButton
                            title={t('infoPanel:Button')}
                            type="primary"
                            job="button"
                            onClick={() => validRedirectOnClick(selectedDuration)}
                            disabled={buttonDisabled} // Pass the disabled prop
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default InfoPanel;
