import {
    Backdrop,
    CircularProgress,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { SignInData } from '../../redux/user/model';
import { GlobalStateType } from '../../redux/rootTypes';
import { clearErrors, loginAction } from '../../redux/user/actions';
import useOnlineDispatch from '../OnlineDispatch';
import SignInManualButton from '../Manuals/SignInManualButton';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFFFFF',
        [theme.breakpoints.down(768)]: {
            padding: 5,
        },
    },
    avatar: {
        margin: theme.spacing(1),
        width: '210px',
        height: '289px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    input: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
        height: '49px',
        fontSize: '16px',
        [theme.breakpoints.down(768)]: {
            width: 'auto',
        },
    },
    password: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
        height: '50px',
        fontSize: '16px',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    fields: {
        textAlign: 'left',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        color: 'white',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        [theme.breakpoints.down(768)]: {
            width: 'auto',
        },
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            '& .MuiButton-label': {
                color: '#00BBFF',
            },
        },
    },
    forgot: {
        color: 'inherit',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '12px',
        textTransform: 'none',
    },
    label: {
        color: 'inherit',
        marginTop: theme.spacing(2),
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '17px',
        fontWeight: 400,
    },
    signup: {
        margin: theme.spacing(2),
        borderRadius: '12px',
        backgroundColor: '##FFFFFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '18px',
        width: '234px',
        height: '56px',
        [theme.breakpoints.down(768)]: {
            width: 'auto',
        },
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));
interface CaptchaLoaderType {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setShowPage: any;
}

const SignUpButton = styled(Button)({
    color: '#0468BF',
});

const SignIn = ({ setShowPage }: CaptchaLoaderType) => {
    const onlineDispatch = useOnlineDispatch();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [checked, setChecked] = React.useState(false);
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [showError, setShowError] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const user = useSelector((state: GlobalStateType) => state.user);
    const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const reRef = useRef<ReCAPTCHA>(null);
    const [showLoader, setShowLoader] = useState(false);
    const verifyCaptcha = (res: any) => {
        if (res) {
            // validateToken(res);
            setCaptchaVerified(true);
        }
    };

    // ReCAPTCHA Expired
    const expireCaptcha = () => {
        setShowPage(false);
        setShowLoader(false);
    };

    useEffect(() => {
        if (user?.errors) setShowError(true);
        if (user.sub) history.push('/home');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(clearErrors());
        setShowError(false);
    };

    function Alert(alertProps: AlertProps) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <MuiAlert elevation={6} variant="filled" {...alertProps} />;
    }

    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!isCaptchaVerified) {
            // Show an alert message if ReCAPTCHA is not verified
            alert('Please complete the ReCAPTCHA before signing in.');
            return;
        }
        onlineDispatch(
            loginAction({ email: email?.trim(), password, remember: checked } as SignInData),
        );
    };

    const signUp = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        history.push('/signup');
    };

    const forgotPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        history.push('/forgot-password');
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Backdrop className={classes.backdrop} open={!!user.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar
                    open={showError}
                    key="topcenter"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity="error">
                        Incorrect username or password.
                    </Alert>
                </Snackbar>
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar variant="square" className={classes.avatar} src="/logo.png" />
                    <form className={classes.paper} noValidate>
                        <div className={classes.fields}>
                            <TextField
                                InputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type="email"
                                id="email"
                                placeholder="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <OutlinedInput
                                className={classes.input}
                                id="password"
                                type={showPassword ? 'string' : 'password'}
                                value={password}
                                onChange={handleChangePassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                                fullWidth
                                name="password"
                                placeholder="Password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checkedIcon={<CircleCheckedFilled />}
                                        checked={checked}
                                        onChange={handleChange}
                                        name="remember-me"
                                        style={{
                                            color: '#FFFFFF',
                                        }}
                                    />
                                }
                                label="Remember me"
                                color="#FFD700"
                            />
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_reCAPTCHA_KEY || ''}
                            ref={reRef}
                            onChange={verifyCaptcha}
                            onExpired={expireCaptcha}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        <Grid item>
                            <Button className={classes.forgot} onClick={forgotPassword}>
                                Forgot your password?
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.label}>
                                Don&apos;t have an account yet?
                            </Typography>
                        </Grid>
                    </form>
                    <Button variant="contained" className={classes.signup} onClick={signUp}>
                        Sign Up Here
                    </Button>
                </div>
            </Container>
            <Container component="main" maxWidth="xs">
                <SignInManualButton />
            </Container>
        </>
    );
};

const mapStateToProps = (state: GlobalStateType) => {
    return {
        loading: state.user.loading,
        errors: state.user.errors,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
