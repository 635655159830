import { API, Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import awsexports from '../../aws-exports';
import { SignInData } from './model';
import { getItem, setItem } from '../../utils/localStorage';
import { getHeader } from '../../utils/api';
import { UserType, USER_KEY } from './types';

API.configure(awsexports);

const apiName = 'User';
const path = '/';
const myInit: {
    headers: string;
    response: true;
    queryStringParameters: { sub?: string };
    body: UserType | null;
} = {
    headers: '',
    response: true,
    queryStringParameters: {},
    body: null,
};

const setUser = (user: UserType) => {
    const localUser = getItem(USER_KEY);
    setItem(USER_KEY, { ...localUser, ...user });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loginService = async (userData: SignInData): Promise<any> => {
    try {
        if (!userData) throw new Error('No data found');
        const { email, password } = userData;
        const user: CognitoUser = await Auth.signIn(email, password);
        if (user) {
            return await new Promise((resolve, reject) =>
                user.getUserAttributes((error, att) => {
                    if (error) reject(error);
                    else {
                        const attributes: Record<string, unknown> = {};
                        att?.forEach((item) => {
                            attributes[item.Name] = item.Value;
                        });
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        setUser(attributes as any);
                        resolve(attributes);
                        window.location.href = '/home';
                    }
                }),
            );
        }
        throw new Error('No user found');
    } catch (err) {
        throw new Error(`${err.message}: ${err.log && err.log}`);
    }
};

export const logoutService = async () => {
    return Auth.signOut().then(() => {
        try {
            localStorage.clear();
            window.location.href = '/login';
        } catch (error) {
            throw new Error(`${error.message}: ${error.log && error.log}`);
        }
    });
};

export const changePassword = async ({
    oldPassword,
    newPassword,
}: {
    oldPassword: string;
    newPassword: string;
}): Promise<boolean> => {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.changePassword(user, oldPassword, newPassword);
    return !!response;
};

export const getUser = async (sub?: string) => {
    const localUser = getItem(USER_KEY);
    myInit.headers = await getHeader(myInit.headers);
    myInit.queryStringParameters.sub = sub || localUser.sub;
    const { data } = await API.get(apiName, path, myInit);
    const user = data?.result?.Item;
    setUser({ ...localUser, ...user });
    return user;
};

export const updateUser = async (user: UserType) => {
    myInit.headers = await getHeader(myInit.headers);
    myInit.body = { ...user };
    delete myInit.body.avatar;
    delete myInit.body.code;
    const { data } = await API.put(apiName, path, myInit);
    setUser(user);
    return data?.result?.Item;
};

export const updateUserSubscriptionFromStripe = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscription: any,
): Promise<UserType | null> => {
    try {
        const localUser = getItem(USER_KEY);
        return await updateUser({
            ...localUser,
            subscription: {
                subscriptionID: subscription.id,
                createdAt: subscription.created,
                subscriptionStatus: subscription.status,
                currentPeriodStart: subscription.current_period_start,
                currentPeriodEnd: subscription.current_period_end,
                pricePlan: subscription.items.data[0].price.id,
                cancelAtPeriodEnd: subscription.cancel_at_period_end,
                cancelAt: subscription.cancel_at,
                canceledAt: subscription.canceled_at,
                pauseCollection: subscription.pause_collection,
            },
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return null;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePaymentsFromStripe = async (payments: any = []): Promise<UserType | null> => {
    // console.log('updatePaymentsFromStripe...');
    try {
        const localUser = getItem(USER_KEY);
        let stripePaymentIntents = payments.map(
            ({ id, status }: { id: string; status: string }) => ({
                id,
                status,
            }),
        );
        if (stripePaymentIntents.length === 0 && localUser?.payment?.paymentIntents?.length > 0) {
            stripePaymentIntents = [...localUser.payment.paymentIntents];
        }
        const newUser = {
            ...localUser,
            payment: {
                paymentIntents: [...stripePaymentIntents],
            },
        };
        // console.log('updatePaymentsFromStripe...', newUser);
        return await updateUser(newUser);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return null;
    }
};

export const updatePaymentFlowStatus = async (paymentFlow: string): Promise<UserType | null> => {
    try {
        // console.log('updatePaymentFlowStatus paymentFlow: ', paymentFlow);
        const localUser = getItem(USER_KEY);
        if (localUser) {
            const newUser = { ...localUser, paymentFlow };
            await updateUser(newUser);
            return newUser;
        }
        return localUser;
    } catch (error) {
        // eslint-disable-next-line no-console
        // console.warn(error);
        return null;
    }
};
