import React from 'react';
import { Dialog, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FractalMedia from './fractalMedia';

const useStyle = makeStyles<Theme>((theme) => ({
    rootStyles: {
        opacity: 1,
    },
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    fullScreenClass: {
        color: 'white',
        fontSize: 20,
        textDecorationLine: 'underline',
        marginBottom: 10,
        cursor: 'pointer',
    },
    root: {
        width: '100%',
        height: '80vh',
        position: 'relative',
        '& video': {
            objectFit: 'cover',
            zIndex: 1,
        },
    },
    rootFullScreen: {
        width: '100%',
        position: 'relative',
        '& video': {
            objectFit: 'cover',
            zIndex: 1,
        },
    },
    subtitle: {
        position: 'absolute',
    },
}));

interface FractalComponenteType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mediaFile: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaReady: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPause: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPlay: any;
    playing: boolean;
    autoPlay: any;
    allMediaReady: any;
}

const Fractal = ({
    handleError,
    mediaFile,
    playing,
    handleMediaReady,
    handleMediaPause,
    handleMediaPlay,
    autoPlay,
    allMediaReady
}: FractalComponenteType) => {
    const classes = useStyle();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        handleMediaPlay();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fractalMedia = () => {
        return (
            <FractalMedia
                handleError={handleError}
                mediaFile={mediaFile}
                playing={playing || open}
                handleMediaReady={handleMediaReady}
                handleMediaPause={handleMediaPause}
                handleMediaPlay={handleMediaPlay}
                autoPlay={autoPlay}
            />
        );
    };

    return (
        <>
            {mediaFile ? (
                <Grid className={classes.rootStyles}>
                    <Grid container justify="flex-end" style={{ marginTop: 20 }}>
                        <Grid item>
                        {allMediaReady() && <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <FullscreenIcon style={{ color: 'white' }} fontSize="large" />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={classes.fullScreenClass}
                                        variant="h4"
                                        color="initial"
                                        onClick={handleClickOpen}
                                    >
                                        {' '}
                                        Click To View full Screen
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        </Grid>
                    </Grid>
                    <div className={classes.root}>
                        {!open ? (
                            fractalMedia()
                        ) : (
                            <Dialog
                                fullScreen
                                open={open}
                                onClose={handleClose}
                                className={classes.rootFullScreen}
                                onClick={handleClose}
                            >
                                {fractalMedia()}
                            </Dialog>
                        )}
                    </div>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
export default Fractal;
