import { useDispatch } from 'react-redux';
import useSubscriptionValidation from './validateBilling';
import { newError } from '../../redux/notifications/actions';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler: any;
}

const ValidateSubscriptionOnClick = ({ handler }: Props) => {
    const dispatch = useDispatch();
    const isValidSubscription = useSubscriptionValidation();

    const redirect = () => {
        // window.location.href = '/my-account';
        dispatch(newError({ severity: 'error', message: 'Sorry, no payment found yet.' }));
    };
    // currently disabling in-app purchases feature
    return isValidSubscription ? handler : redirect;
    // return handler;
};

export default ValidateSubscriptionOnClick;
