import { Grid, makeStyles, Theme, Typography, Select, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BigButton from '../utils/Button/BigButton';
import panelData from '../../i18n/en/infoPanel.json';
import { changeHarmonizeTime } from '../../redux/panels/actions';

interface PanelData {
    key: string,
    glassPlaceholder: string;
};

const useStyle = makeStyles<Theme>((theme) => ({
    rootStyles: {
        height: '100%',
        width: '100%',
        position: 'fixed',
        opacity: 1,
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
    },
    alternativeRootStyles: {
        height: '100%',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        background: `url('../assets/bg.png')`,
        opacity: 1,
    },

    glassPlaceHolder: {
        height: '324px',
        width: '324px',
        marginTop: '26px',
        marginBottom: '21px',
        backgroundColor: '#f0f5fd',
        borderRadius: '50%',

        // opacity: '32%',
        // background: `url('../assets/images/Ellipse43.svg') no-repeat center fixed;`,
        [theme.breakpoints.down(800)]: {
            height: '324px',
        },
    },
    insidePlaceholder: {
        height: '280px',
        width: '280px',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        // background: `url('../assets/images/Ellipse42.svg') no-repeat center fixed;`,
        [theme.breakpoints.down(800)]: {
            height: '280px',
        },
    },
    infoName: {
        margin: '30px',
        fontWeight: 900,
        color: '#0468BF',
        fontSize: 36,
    },

    infoDescription: {
        fontFamily: ['Poppins', 'regular'].join(','),
        marginBottom: '30px',
        color: '#383838',
        fontSize: '16px',
    },
    dropdown: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#f0f5fd",
    },
    select: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: theme.spacing(3),

            },
        },
        '& .MuiSelect-select': {
            color: 'white',
            backgroundColor: "#00BBFF",
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: "#00BBFF",
        },
        '& .MuiSelect-icon': {
            color: 'white',
        },
        '& .MuiListItem-root': {
            color: 'white',
        },
        '& .MuiList-root': {
            backgroundColor: "#00BBFF",
        },
    },
}));

const InfoPanel = ({
    panel,
    validRedirectOnClick,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    panel: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validRedirectOnClick: any;
}) => {
    const classes = useStyle();
    const { t } = useTranslation(['infoPanel']);
    const [selectedDuration, setSelectedDuration] = useState<number | null>(null); // Default to 3 minutes
    const [placeholderText, setPlaceholderText] = useState<string>(panelData?.[3]?.glassPlaceholder || '');
    const [buttonDisabled, setButtonDisabled] = useState(true); // Initially disable the button
    const dispatch = useDispatch();
    
    const handleChangeDuration = (event: React.ChangeEvent<{ value: unknown }>) => {
        const duration = event.target.value as number;
        console.log("Dropdown value changed:", event.target.value);
        setSelectedDuration(duration);
        if (duration === 3) {
            dispatch(changeHarmonizeTime({harmonizeTime:180000}));
            const placeholder = panelData?.['3']?.glassPlaceholder;
            setPlaceholderText(placeholder);
        } else {
            dispatch(changeHarmonizeTime({harmonizeTime:60000}));
            const placeholder = panelData?.['1']?.glassPlaceholder;
            setPlaceholderText(placeholder);
        }
        setButtonDisabled(false); // Enable the button when a duration is selected
        console.log(" after Button disabled:", buttonDisabled);
    };
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
        >
            <Grid
                container
                justify="center"
                alignContent="center"
                alignItems="center"
                className={classes.glassPlaceHolder}
            >
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                    className={classes.insidePlaceholder}
                >
                    <Grid item container justify="center" alignContent="center" alignItems="center">
                        <img
                            alt="glass-of-water"
                            style={{ height: 50, width: 30, marginBottom: '10px' }}
                            src="../assets/icons/water-glass.svg"
                        />
                        <Typography variant="body1" align="center">
                            {panel?.description}
                            {/* {placeholderText} */}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {panel && (
                <Grid container spacing={2} justify="center" direction="column" alignItems="center">
                    <Grid item className={classes.dropdown}>
                        <Select
                            value={selectedDuration || ''}
                            onChange={handleChangeDuration}
                            variant="outlined"
                            fullWidth
                            className={classes.select}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Please select duration</MenuItem>
                            <MenuItem value={3}>3 min video</MenuItem>
                            <MenuItem value={1}>1 min video</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item>
                        {' '}
                        <BigButton
                            title={t('infoPanel:Button')}
                            type="primary"
                            job="button"
                            onClick={() => validRedirectOnClick(selectedDuration)}
                            disabled={buttonDisabled}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default InfoPanel;
