import {
    LIST_PANEL,
    LIST_PANEL_SUCCEEDED,
    LIST_PANEL_FAILED,
    GET_PANEL,
    GET_PANEL_SUCCEEDED,
    GET_PANEL_FAILED,
    ErrorType,
    PanelType,
    PanelsType,
    SET_CURRENT_ACTIVITY,
    SET_CURRENT_ACTIVITY_SUCCEEDED,
    SET_CURRENT_ACTIVITY_FAILED,
    CHANGE_HARMONIZE_TIME,
    RESET_HARMONIZE_TIME,
} from './types';

export const getPanelAction = (payload: { id: string }) => ({
    type: GET_PANEL,
    payload,
});

export const getPanelSuccessAction = (payload: { panel: PanelType }) => ({
    type: GET_PANEL_SUCCEEDED,
    payload,
});

export const getPanelFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_PANEL_FAILED,
    payload,
});

export const listPanelAction = () => ({
    type: LIST_PANEL,
});

export const listPanelSuccessAction = (payload: PanelsType) => ({
    type: LIST_PANEL_SUCCEEDED,
    payload,
});

export const listPanelFailedAction = (payload: { error: ErrorType }) => ({
    type: LIST_PANEL_FAILED,
    payload,
});

export const setActivityAction = (payload: { id: string }) => ({
    type: SET_CURRENT_ACTIVITY,
    payload,
});

export const seActivitySuccessAction = (payload: PanelType) => ({
    type: SET_CURRENT_ACTIVITY_SUCCEEDED,
    payload,
});

export const setActivityFailedAction = (payload: { error: ErrorType }) => ({
    type: SET_CURRENT_ACTIVITY_FAILED,
    payload,
});

export const resetHarmonizeTime = () => ({
    // type: 'RESET_HARMONIZE_TIME',
    type: RESET_HARMONIZE_TIME,
});

export const changeHarmonizeTime = (payload: { harmonizeTime: number }) => ({
    type: CHANGE_HARMONIZE_TIME,
    payload,
});