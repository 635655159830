import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { GlobalStateType } from '../../redux/rootTypes';
import { setActivityAction } from '../../redux/panels/actions';
import useValidateSubscriptionOnClick from '../BillingStatus/validateBillingOnClick';
import InfoPanelMobile from './InfoPanelMobile';
import InfoPanelDesktop from './InfoPanelDesktop';
import ShowInfoMobile from './ShowInfoMobile';
import ShowInfoDesktop from './ShowInfoDesktop';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HarmonizerPanel = ({ panel }: { panel: any }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const {
        header: { isShowingInfo },
    } = useSelector((state: GlobalStateType) => state);

    const handleStartButton = () => {
        dispatch(setActivityAction({ id }));
        history.push(`/harmonizing-screen/${id}`);
    };

    // currently disabling in-app purchases feature

    const validRedirectOnClick = useValidateSubscriptionOnClick({
        handler: handleStartButton,
    });
    // const validRedirectOnClick = handleStartButton;
    return isMobile ? (
        <>
            {isShowingInfo ? (
                <InfoPanelMobile panel={panel} validRedirectOnClick={validRedirectOnClick} />
            ) : (
                <ShowInfoMobile panel={panel} validRedirectOnClick={validRedirectOnClick} />
            )}
        </>
    ) : (
        <>
            {isShowingInfo ? (
                <ShowInfoDesktop panel={panel} validRedirectOnClick={validRedirectOnClick} />
            ) : (
                <InfoPanelDesktop panel={panel} validRedirectOnClick={validRedirectOnClick} />
            )}
        </>
    );
};

export default HarmonizerPanel;
