import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import BigButton from '../utils/Button/BigButton';
import { resetHarmonizeTime } from '../../redux/panels/actions';

const useStyle = makeStyles<Theme>((theme) => ({
    rootStyles: {
        height: '100vh',
        width: '100vw',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        opacity: 1,
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center' /* Center the image */,
        mixBlendMode: 'normal',
        [theme.breakpoints.down(800)]: {
            background: `url('../assets/layout/waterharmonizer-main-BG.png') no-repeat center bottom fixed;`,
            '-webkit-background-size': 'cover',
            '-o-background-size': 'cover',
            backgroundSize: 'cover',
            mixBlendMode: 'normal',
        },
    },
    outSideCircle: {
        height: '324px',
        width: '324px',
        marginTop: '26px',
        marginBottom: '21px',
        backgroundColor: '#FFFFFF40',
        borderRadius: '50%',

        [theme.breakpoints.down(800)]: {
            height: '324px',
        },
    },
    inSideCircle: {
        height: '280px',
        width: '280px',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        background: `url('../assets/Complete circle background.svg') no-repeat center;`,
        marginTop: 0,
    },
    text: {
        fontFamily: ['Poppins', 'regular'].join(','),
        marginBottom: 20,
        fontSize: 21,
        color: 'white',
    },
    infoDescription: {
        fontFamily: ['Poppins', 'regular'].join(','),
        marginBottom: '10px',
        color: '#FFD700',
        fontSize: '36px',
    },
    main: {
        background: 'blue',
        width: '100vw',
        height: '100vh',
        marginLeft: '-20px !important',
        marginRight: '-20px !important',
    },
}));

const HarmonizedComplete = () => {
    const classes = useStyle();
    const history = useHistory();
    // const dispatch = useDispatch();
    const location = useLocation();
    const pathSegments = location.hash.split('#');
    // console.log('Latitude:', pathSegments);
    const handleHarmonizeAgain = () => {
        // dispatch(resetHarmonizeTime());
        history.push(`/harmonizing-screen/${pathSegments[1]}#harmonizing`);
        // history.goBack();
    };

    const goMainScreen = () => {
        history.push('/home');
    };

    return (
        <div className={classes.rootStyles}>
            <Grid
                container
                direction="column"
                justify="center"
                alignContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                    className={classes.outSideCircle}
                >
                    <Grid
                        container
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                        className={classes.inSideCircle}
                    >
                        <Grid
                            item
                            container
                            justify="center"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Typography
                                variant="body1"
                                align="center"
                                className={classes.infoDescription}
                            >
                                Complete
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography variant="body2" className={classes.text}>
                    Your water is ready to drink
                </Typography>
                <BigButton
                    title="Harmonize Again"
                    type="white"
                    job="button"
                    onClick={handleHarmonizeAgain}
                />
                <br />
                <BigButton
                    title="Back To Main Screen"
                    type="primary"
                    job="button"
                    onClick={goMainScreen}
                />
            </Grid>
        </div>
    );
};

export default HarmonizedComplete;
