import { LinearProgress, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { GlobalStateType } from '../../redux/rootTypes';
import { changeLanguage } from '../../redux/app/actions';
import { getUserAction } from '../../redux/user/actions';
import { capitalizeFirstLetter } from '../../utils/string';
import { listPaymentIntentAction } from '../../redux/payments/actions';
import HeaderMobile from './HeaderMobile';
import HeaderDesktop from './HeaderDesktop';
import useOnlineDispatch from '../OnlineDispatch';
import WaitingForPaymentFlow from '../BillingStatus/waitingForPaymentFlow';

const useStyle = makeStyles<Theme>((theme) => ({
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const Header = () => {
    const { t } = useTranslation(['header']);
    const theme = useTheme();
    const classes = useStyle();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    const history = useHistory();
    const onlineDispatch = useOnlineDispatch();
    const { language } = useSelector((state: GlobalStateType) => state.header);
    const { activity } = useSelector((state: GlobalStateType) => state.panels);
    const {
        loader,
        user: { loading: userLoading, paymentFlow, sub, stripeCustomerID },
        subscription: { loading: subscriptionLoading },
        payments: { loading: paymentLoading },
    } = useSelector((state: GlobalStateType) => state);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(
            loader.data ||
                userLoading ||
                subscriptionLoading ||
                paymentLoading ||
                paymentFlow === 'pending',
        );
    }, [loader, userLoading, subscriptionLoading, paymentLoading, paymentFlow]);

    useEffect(() => {
        // currently disabling in-app purchases feature
        if (sub) {
            onlineDispatch(getUserAction({ sub }));
        }
        onlineDispatch(changeLanguage(language));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // currently disabling in-app purchases feature
    useEffect(() => {
        if (stripeCustomerID) {
            onlineDispatch(listPaymentIntentAction({ customerID: stripeCustomerID }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeCustomerID]);

    const setHeaderText = () => {
        const paths = history.location.pathname.split('/');
        if (paths.includes('panel-info')) {
            return activity?.name ? `${activity?.name} Panel` : '...';
        }
        if (paths.includes('harmonizing-screen')) {
            return activity?.name ? `${activity?.name} Panel` : 'Harmonizing Screen';
        }
        if (
            paths.some((path) =>
                ['about', 'solfeggio-frequencies', 'messages', 'privacy-policy'].includes(path),
            )
        ) {
            return '';
        }
        return t(
            `header:${history.location.pathname
                .split('/')
                .pop()
                ?.split('-')
                .map((word) => capitalizeFirstLetter(word))
                .join(' ')}`,
        );
    };

    return (
        <>
            {isMobile ? (
                <HeaderMobile setHeaderText={setHeaderText} />
            ) : (
                <HeaderDesktop setHeaderText={setHeaderText} />
            )}
            {loading ? <LinearProgress className={classes.rootLinear} /> : null}
             {/* // currently disabling in-app purchases feature    */}
            <WaitingForPaymentFlow />  
        </>
    );
};

export default Header;
