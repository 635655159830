import React, { createContext, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Center from './center';
import Panel from '../Panel/panel';

interface FractalComponenteType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mediaFile: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaReady: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPause: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPlay: any;
    playing: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    autoPlay: any;
}

const FractalMedia = ({
    handleError,
    mediaFile,
    playing,
    handleMediaReady,
    handleMediaPause,
    handleMediaPlay,
    autoPlay,
}: FractalComponenteType) => {
    const onReady = () => {
        console.log('fractal ready to play...');
        handleMediaReady('fractal');
    };
    const [ingredients, setIngredients] = useState<string[]>([]);
    const { pathname } = window.location;
    useEffect(() => {
        if (pathname === '/harmonizing-screen/01') {
            setIngredients([
                'Leptin',
                'African Mango',
                'Alpha-Lipoic Acid',
                'Fish Oil',
                'Green Tea Extract',
                'Conjugated Linoleic Acid',
                'Lipase',
                'Glucagon',
                'Adrenaline',
                'T3',
                'T4',
                'Testosterone',
                'Chromium',
                'Garcinia Cambogia',
                'Bitter Orange',
                'Coleus forskohlii',
                'Beta Glucans',
                'Raspberry Ketones',
                'Caffeine',
                'Capsaicin',
                'Hoodia',
                'Probiotics',
                'Prebiotics',
                'Harmonized Water',
                'Green Coffee Bean Extract',
                'Fucoxanthin',
                'Carnitine',
                'Pyruvate',
                'Guarana',
                'Yohimbe',
                'Vitamin D',
                'Glucomannan',
                'Chitosan',
                'Vitamin C',
                'Vitamin A',
                'Progesterone',
                'DHEA',
                'Guar Gum',
                'Chamomile',
                'Kava Kava',
                'Lavender',
                'Skullcap',
                'Passion Flower',
                // 'Digest',
                // 'Enzymes',
                // 'Amylase',
                // 'Protease',
                // 'Xylanase',
                // 'Lipase',
                // 'Alpha galactosidase',
                // 'Lactase',
                // 'Glucoamylase',
                // 'Pectinase',
                // 'Cellulase',
                // 'Hemicellulase',
                // 'Invertase',
                // 'Diastase',
                // 'Beta-glucanase',
                // 'Phytase',
                // 'Papain',
                // 'Bromelain',
                // 'Amino Acids',
                // 'Essential',
                // 'Histidine',
                // 'Isoleucine',
                // 'Leucine',
                // 'Methionine',
                // 'Phenylalanine',
                // 'Threonine',
                // 'Tryptophan',
                // 'Valine',
                // 'Nonessential',
                // 'Alanine',
                // 'Arginine',
                // 'Asparagine',
                // 'Aspartate',
                // 'Cysteine',
                // 'Glutamate',
                // 'Glutamine',
                // 'Glycine',
                // 'Proline',
                // 'Serine',
                // 'Tyrosine',
                // 'Prebiotics',
                // 'Inulin',
                // 'Organic chicory root powder',
                // 'Tiger Nut',
                // 'Lentil root powder',
                // 'Onions',
                // 'Garlic',
                // 'Organic gum arabic flour',
                // 'Leeks',
                // 'Alpha galactosidase',
                // 'Probiotics',
                // 'Found In',
                // 'Yogurt',
                // 'Kefir',
                // 'Cottage Cheese',
                // 'Sauerkraut',
                // 'Miso',
                // 'Pickled Vegetables',
                // 'Streptococcus thermophiles',
                // 'Pediococcus acidilactici',
                // 'Bifidobacterium breve',
                // 'Bifidobacterium infantis',
                // 'Bifidobacterium longum',
                // 'Lactobacillus acidophilus',
                // 'Lactobacillus brevis',
                // 'Lactobacillus brevis',
                // 'Lactobacillus bulgaricus',
                // 'Lactobacillus casei',
                // 'Lactobacillus gasseri',
                // 'Lactobacillus plantarum',
                // 'Lactobacillus paeacasei',
                // 'Lactobacillus rhamnous',
                // 'Lactococcus lactis',
                // 'Bifidobacterium lacus',
                // 'Lactobacillus salivarus',
                // 'Bacillus laterosporus',
                // 'Additional Support',
                // 'Harmonized pure water',
                // 'Intrasound powder',
                // 'Celtic',
                // 'Sea',
                // 'Pink Himalayan Salt',
                // 'Organic juiced veggies',
                // 'Fulvic/Humic Acid',
                // 'Peaceful enviroment',
            ]);
        }
    }, [pathname]); // Get the current URL path
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/02') {
    //         setIngredients([
    //             'Caffeine',
    //             'Green Tea',
    //             'Guarana',
    //             'Maca',
    //             'Curcumin',
    //             'Vitamin E',
    //             'Yerba Mate',
    //             'Ginseng (Panax & American)',
    //             'Chromium',
    //             'B2',
    //             'B1',
    //             'B6',
    //             'B12',
    //             'B5',
    //             'Rhodiola',
    //             'l-Arginine',
    //             'l-Citroline',
    //             'Eleuthero',
    //             'Kelp',
    //             'Astragalus',
    //             'Cordyceps',
    //             'Shitake',
    //             'Schisandra',
    //             'Ashwagandha',
    //             'Taurine',
    //             'Ginger',
    //             'Red Clover',
    //             'Triple Frozen Water (Harmonized)',
    //             'Intrasound Powder',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/03') {
    //         setIngredients([
    //             'Wintergreen',
    //             'Lavander',
    //             'Lavadin',
    //             'Frankincense',
    //             'Lemongrass',
    //             'Cedarwood',
    //             'Orange',
    //             'Patchouli',
    //             'Tangerine',
    //             'Chamomile',
    //             'Ylang Ylang',
    //             'Sandalwood',
    //             'Coriander',
    //             'Roman Chamomile',
    //             'Lemongrass',
    //             'Lemon peel',
    //             'Jasmine',
    //             'Rose',
    //             'Geranium',
    //             'Peppermint',
    //             'Sweet Marjoram',
    //             'Valerian Root',
    //             'Bergamot',
    //             'Hops',
    //             'Lemon Balm',
    //             'Passion Flower',
    //             'L-tryptophan',
    //             'L-theanine',
    //             'Melatonin',
    //             'Magnesium',
    //             'Resonated Clay',
    //             'Chromium',
    //             'Catnip',
    //             'Celery Seed',
    //             'Ashwagandha',
    //             'Gotu Kola',
    //             'Tulsi leaf',
    //             'Chamomile',
    //             'Oat Straw',
    //             'Skullcap',
    //             'Spearmint',
    //             'Fennel seed',
    //             'Valerian root',
    //             'Hawthorn',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/04') {
    //         setIngredients([
    //             'Arcinia Montana',
    //             'Willow Bark',
    //             'Turmeric',
    //             'Hemp',
    //             'Wild Lettuce',
    //             'Cloves',
    //             'Lavender Essential Oil',
    //             'Rosemary Essential Oil',
    //             'Peppermint Essential Oil',
    //             'Eucalyptus Essential Oil',
    //             'Ginger',
    //             'Feverfew',
    //             'Capsaicin',
    //             'Devils Claw',
    //             'Gamma-linolenic acid',
    //             'Boswellia',
    //             'Fennel',
    //             'Green Tea',
    //             'Licorice root',
    //             'Oregano',
    //             'Chamomile',
    //             'Skullcap',
    //             'Goldenrod',
    //             'Kava',
    //             'Bromelain',
    //             'Rock Rose',
    //             'Comfrey',
    //             'Glucosamine',
    //             'Chondroitin',
    //             'Resonance Gel / Powder',
    //             'Harmonized Water',
    //             'Aloe Vera',
    //             'Cats Claw',
    //             'Thunder god vine',
    //             'Cinnamon',
    //             'Black Pepper',
    //             'Menthol',
    //             'Resveratrol',
    //             'Myrrh',
    //             'Spikenard',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/05') {
    //         setIngredients([
    //             'Oily Fish',
    //             'Berries',
    //             'Nuts',
    //             'Seeds',
    //             'Dark Chocolate',
    //             'Whole Grains',
    //             'Blueberries',
    //             'Avocados',
    //             'Freshly Brewed Tea',
    //             'Folic Acid',
    //             'Vitamin E',
    //             'B6',
    //             'B12',
    //             'Vitamin D',
    //             'Ginkgo biloba',
    //             'Omega-3 fatty acid',
    //             'Huperzine A',
    //             'Panax ginseng',
    //             'DHEA',
    //             'Bacopa',
    //             'Acetyl-L-carnitine',
    //             'Turmeric',
    //             'Curcumin',
    //             'Phosphatidylserine',
    //             'N-Acetyl L-Tyrosine',
    //             'Acetyl-l-Carnitine',
    //             'American Ginseng',
    //             'GABA',
    //             "Lion's Mane",
    //             'Tryptophan',
    //             'Vinpocetine',
    //             'L-Theanine',
    //             'Acetyl-l-Carnitine',
    //             'Creatine',
    //             'Taurine',
    //             'Tyrosine',
    //             'Theanine',
    //             'American ginseng',
    //             'Rosemary',
    //             'Magnesium',
    //             'Fulvic / Humic Acid',
    //             'Intrasound Powder',
    //             'Harmonized Water',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/06') {
    //         setIngredients([
    //             'Eyebright',
    //             'Blueberry Extract',
    //             'Marigold',
    //             'Vitamin A',
    //             'Bilberry Extract',
    //             'Grape Seed Extract',
    //             'Lutein',
    //             'Alpha Lipoic Acid',
    //             'Taurine',
    //             'Zeaxanthin',
    //             'Saffron',
    //             'Mixed Carotenoids',
    //             'Black Currant',
    //             'Omega Oil',
    //             'Ginkgo biloba',
    //             'Green Tea',
    //             'Coleus',
    //             'Garlic',
    //             'Tumeric',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/07') {
    //         setIngredients([
    //             'Arnica Montana',
    //             'Harmonized Water',
    //             'Turmeric Curcumin',
    //             'Boswellia',
    //             'Quercetin',
    //             'Cinnamon',
    //             'Protease',
    //             'Serratiopeptidase',
    //             'Resveratrol',
    //             'Pycnogenol (Maritime Pine Bark)',
    //             'Rutin',
    //             'Rosemary',
    //             'Acerola',
    //             'White Willow Bark',
    //             'Green Tea',
    //             'Capsaicin',
    //             'Cats Claw',
    //             'Black Pepper',
    //             'Cloves',
    //             'Ginger',
    //             'Red Clover',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/08') {
    //         setIngredients([
    //             'Alpha Lipoic Acid',
    //             'Avocado',
    //             'Artichoke',
    //             'Asparagus Root',
    //             'Aloe Vera',
    //             'Activated Charcoal',
    //             'Atlantic Red Algae',
    //             'Atlantic Dulse',
    //             'Aluminum',
    //             'Burdock Root',
    //             'Barley Grass',
    //             'Brewers Yeast',
    //             'Blue Green Algae',
    //             'Borotutu Bark',
    //             'Beet',
    //             'Calcium',
    //             'Cascara Sagrada',
    //             'Chinese Rhubarb',
    //             'Cranberry',
    //             'Chickweed',
    //             'Chanca Piedra',
    //             'Corn Silk',
    //             'Cleavers',
    //             'Cayenne',
    //             'Chicory Root',
    //             'Chloe Ella',
    //             'Cilantro',
    //             'Citrus Fruits',
    //             'Celery Seed',
    //             'Chromium',
    //             'Copper',
    //             'Cadmium',
    //             'Cobalt',
    //             'Dandelion Root',
    //             'EDTA',
    //             'Flax Seed Powder',
    //             'Fennel',
    //             'Fenugreek',
    //             'Ginger',
    //             'Glutathione',
    //             'Gallium',
    //             'Garlic',
    //             'Grape seed Extract',
    //             'Green Tree',
    //             'Ginkgo Biloba Leaf',
    //             'Goldenrod',
    //             'Harmonized Water',
    //             'Horsetail Reed',
    //             'Hydrangea',
    //             'Iron',
    //             'Juniper Berry',
    //             'Kale',
    //             'Lemon',
    //             'Lemon Juice',
    //             'Lead',
    //             'Marshmallow Root',
    //             'Milk Thistle',
    //             'Methionine',
    //             'Manganese',
    //             'Mercury',
    //             'Maitake',
    //             'Nettle Root',
    //             'N Acetyl cysteine',
    //             'Nickel',
    //             'Olive Leaf',
    //             'Parsley',
    //             'Prune Powder',
    //             'Psyllium',
    //             'Probiotics',
    //             'Peppermint',
    //             'Peppermint Leaf',
    //             'Rosemary',
    //             'Resonance Powder',
    //             'Reshi',
    //             'Shilajit',
    //             'Sarsaparilla',
    //             'Senna Leaf',
    //             'Slippery Elm',
    //             'Spirulina',
    //             'Selenium',
    //             'Shiitake',
    //             'Taurine',
    //             'Titanium',
    //             'Turmeric',
    //             'Turkey Tail Mushroom',
    //             'Uva Ursi Leaf',
    //             'Vegetable Fiber',
    //             'Vanadium',
    //             'Vitamin C',
    //             'Vitamin E',
    //             'Wild Blueberry',
    //             'Yellow Dock Root',
    //             'Zinc',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/09') {
    //         setIngredients([
    //             '174Hz',
    //             '285Hz',
    //             '396 Hz',
    //             '3-Diindolylmethane',
    //             '40Hz',
    //             '417 Hz',
    //             '432Hz',
    //             '528Hz',
    //             '639 Hz',
    //             '741 Hz',
    //             '852 Hz',
    //             '852Hz',
    //             '963Hz',
    //             'African Mango',
    //             'Agnus Castus',
    //             'Alpha Lipoic Acid',
    //             'Arnica',
    //             'Ashwagandha',
    //             'Avena Sativa',
    //             'Bananas',
    //             'Bergamot Essential Oil',
    //             'Betaine',
    //             'Bladderwort',
    //             'Broccoli',
    //             'Calm',
    //             'Carduus Marianus',
    //             'Carrots',
    //             'Catuaba',
    //             'Cauliflower',
    //             'Celery',
    //             'Chaga',
    //             'Chamomile Essential Oil',
    //             'Chinese Cabbage',
    //             'Choline',
    //             'Chromium',
    //             'Cinnamon',
    //             'Coleus Forskohilli',
    //             'Copper',
    //             'Cordyceps',
    //             'Cucumbers',
    //             'D-aspartic Acid',
    //             'DHEA',
    //             'DIM',
    //             'ECGC',
    //             'Endive',
    //             'Epimedium',
    //             'Fatty Acids',
    //             'Fennel',
    //             'Fenugreek',
    //             'Fulvic Acid',
    //             'GABA',
    //             'Geranium Essential Oil',
    //             'Ginger',
    //             'Ginseng',
    //             'Glutamine',
    //             'Glycine',
    //             'Gotu Kola',
    //             'Grape Seed Extract',
    //             'Green Tea Extract',
    //             'Harmonized Water',
    //             'Hepar Sulph',
    //             'HGH',
    //             'Horney Goat Weed',
    //             'Humic Acid',
    //             'Hypophysis Suis',
    //             'Indole-3-Carbinol',
    //             'Jasmine Essential Oil',
    //             'Kelp',
    //             'L- Arginine',
    //             'L-Citrulline',
    //             'Leeks',
    //             'L-Lysine',
    //             'Longjack',
    //             'Lycopodium',
    //             'Maca',
    //             'Magnesium',
    //             'Maritime Pine Bark',
    //             'Muira Puama',
    //             'Neroli Essential Oil',
    //             'Nitric Oxide',
    //             'Omega 3',
    //             'Omega 6',
    //             'Omega 9',
    //             'Oranges',
    //             'Oxygen',
    //             'Panax Ginseng',
    //             'Patchouli Essential Oil',
    //             'Peacefulness',
    //             'Phosphoric Ascidium',
    //             'Pumpkin Seed Extract',
    //             'Quercetin',
    //             'Radishes',
    //             'Reishi',
    //             'Enhance Relationships',
    //             'Rescue Remedy',
    //             'Responsibility',
    //             'Rhodiola Rosea',
    //             'Rose Essential Oil',
    //             'Royal Jelly',
    //             'Saw Palmetto',
    //             'Selenium',
    //             'Suma',
    //             'Testosterone',
    //             'Tongkat Ali',
    //             'Tribulus terrestris',
    //             'Tumeric',
    //             'Turnips',
    //             'Valerian',
    //             'Vitamin A',
    //             'Vitamin B12',
    //             'Vitamin B6',
    //             'Vitamin C',
    //             'Vitamin D3',
    //             'Vitamin E',
    //             'Worthiness',
    //             'Ylang-Ylang Essential Oil',
    //             'Yohimbe',
    //             'Zinc',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/10') {
    //         setIngredients([
    //             '174Hz',
    //             '285Hz',
    //             '396Hz',
    //             '40Hz',
    //             '417 Hz',
    //             '432Hz',
    //             '528Hz',
    //             '639 Hz',
    //             '741 Hz',
    //             '852Hz',
    //             '963Hz',
    //             'Acetylcholine',
    //             'Acid Phos',
    //             'Activated Charcoal',
    //             'Aethusa',
    //             'Agnus Castus',
    //             'Alkalized Harmonized Water',
    //             'Aloe Vera',
    //             'Alpha Lipoic Acid',
    //             'Alpha-Galactosidase',
    //             'Amla',
    //             'Amylase',
    //             'Anandamide',
    //             'Angelica',
    //             'Angelica oil',
    //             'Anise Oil',
    //             'Antimonium Crudum',
    //             'Antioxidants',
    //             'Ashwaganda',
    //             'Ashwangda',
    //             'Astragalus Root',
    //             'ATP',
    //             'Avena Sativa',
    //             'Bergamot Oil',
    //             'Black Cohosh',
    //             'Black Pepper',
    //             'Blue Cohosh',
    //             'Boron',
    //             'Burdock',
    //             'Calcarea Carb',
    //             'Calcium',
    //             'Calm',
    //             'Catuaba Bark',
    //             'Chamomile',
    //             'Chase Berry',
    //             'Chaseberry Oil',
    //             'Chromium',
    //             'Citicoline',
    //             'Clary Sage Oil',
    //             'Conium',
    //             'Cordyceps',
    //             'Cordyceps',
    //             'Cramp Bark',
    //             'Dandelion',
    //             'Dandelion Root',
    //             'Dark Chocolate',
    //             'DHEA',
    //             'Dong Quai',
    //             'Dopamine',
    //             'D-Serine',
    //             'Eleuthero Root',
    //             'Endorphins',
    //             'Enkephalin',
    //             'Epimedium',
    //             'Epinephrine',
    //             'Evening Primrose Oil',
    //             'Fennel Oil',
    //             'Fenugreek',
    //             'Frankincense Oil',
    //             'Frustration',
    //             'Fulvic Acid',
    //             'Gamma-Aminobutyric Acid (GABA)',
    //             'Garlic',
    //             'Geranium Oil',
    //             'Ginger',
    //             'Gingko Biloba',
    //             'Ginseng',
    //             'Glutanate',
    //             'Glycine',
    //             'Harmonized Water',
    //             'HGH',
    //             'Histamine',
    //             'Hops',
    //             'Humic Acid',
    //             'Ignatia',
    //             'Iodine',
    //             'Iodum',
    //             'Jiaogulan',
    //             'Lachesis',
    //             'Lavender',
    //             'Lavender Oil',
    //             'Lemon Balm',
    //             'Leptin',
    //             'L-Theanine',
    //             'Maca',
    //             'Maca',
    //             'Magnesium',
    //             'Maritime Pine Bark',
    //             'Milk Thistle',
    //             'Motherwort',
    //             'Muira Puama',
    //             'Myrtle Oil',
    //             'N-Acetyl L-Tyrosine',
    //             'Natrum Mur',
    //             'Norepinephrine',
    //             'Nuphar Luteum',
    //             'Oat Straw',
    //             'Oats',
    //             'Octopamine',
    //             'Oregano Oil',
    //             'Peacefulness',
    //             'Peppermint Oil',
    //             'Phenethylamine',
    //             'Phosphatidylserine',
    //             'Pine Oil',
    //             'Propolis',
    //             'Pulsatilla',
    //             'Quercetin',
    //             'Radishes',
    //             'Red Clover',
    //             'Red Raspberry Leaf',
    //             'Reishi',
    //             'Enhance Relationships',
    //             'Rescue Remedy',
    //             'Responsibility',
    //             'Rhodiola Rosea',
    //             'Rock Rose',
    //             'Rose Essential Oil',
    //             'Rose Hips',
    //             'Rose Oil',
    //             'Rosemary',
    //             'Rosemary Oil',
    //             'Royal Jelly',
    //             'Sage',
    //             'Sage Oil',
    //             'Schisandra Berries',
    //             'Selenium',
    //             'Sepia',
    //             'Serotonin',
    //             'Shilajit',
    //             'Skullcap',
    //             'Somatostatin',
    //             'St John’s Wort',
    //             'Tea Tree Oil',
    //             'Testosterone',
    //             'Thyme',
    //             'Tongkat Ali',
    //             'Tribulus Terrestris',
    //             'Trypramine',
    //             'Tulsi',
    //             'Turmeric',
    //             'Turnips',
    //             'Valerian',
    //             'Vitamin A',
    //             'Vitamin B12',
    //             'Vitamin B6',
    //             'Vitamin C',
    //             'Vitamin D3',
    //             'Vitamin E',
    //             'Vitex',
    //             'Wild Yam',
    //             'Worthiness',
    //             'Ylang Ylang Oil',
    //             'Zinc',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/11') {
    //         setIngredients([
    //             'Amino Acids:',
    //             'Alanine',
    //             'Asparagine',
    //             'Aspartic Acid',
    //             'Cysteine',
    //             'Glutamic Acid',
    //             'Glutamine',
    //             'Glycine',
    //             'Phenylalanine',
    //             'Proline',
    //             'Serine',
    //             'Tyrosine',
    //             'Support elements:',
    //             'Schumann Resonance Frequencies',
    //             'Negative Ions',
    //             'Ozone O3',
    //             'Oxygen',
    //             'Hydrogen 02',
    //             'Vitamins:',
    //             'Biotin',
    //             'CO Q10',
    //             'Fulvic Acid',
    //             'Humic Acid',
    //             'Vitamin A',
    //             'Vitamin B1',
    //             'Vitamin B12',
    //             'Vitamin B17',
    //             'Vitamin B3',
    //             'Vitamin B5',
    //             'Vitamin B7',
    //             'Vitamin B8',
    //             'Vitamin B9',
    //             'Vitamin C',
    //             'Vitamin D3',
    //             'Vitamin E',
    //             'Vitamin K',
    //             'Vitamin K2',
    //             'Minerals:',
    //             'Calcium',
    //             'Chloride',
    //             'Chromium',
    //             'Concentrated Ocean Water',
    //             'Copper',
    //             'Fluoride',
    //             'Iodine',
    //             'Iron',
    //             'Magnesium',
    //             'Manganese',
    //             'Molybdenum',
    //             'Okinawa Sango Coral Calcium',
    //             'Phosphorus',
    //             'Potassium',
    //             'Purified Ocean Water',
    //             'Sodium',
    //             'Zinc',
    //             'Lithium Orotate',
    //             'Glutathione',
    //             'Glucosamine',
    //             'Naturopathic:',
    //             'Acerola',
    //             'Aloe Vera',
    //             'Almonds',
    //             'Ashwaganda',
    //             'Apricot Kernel Oil',
    //             'Beet Leaf',
    //             'Black Pepper Essential Oil',
    //             'Blueberries',
    //             'Cacoa',
    //             'Celery Seed Extract',
    //             'Chia Seeds',
    //             'Choke berry',
    //             'Cilantro',
    //             'Collagen',
    //             'Coconut Oil',
    //             'Coconut Amino’s',
    //             'Colloidal Silver',
    //             'Colostrum',
    //             'Curcumin',
    //             'Geranium',
    //             'Gogi Berries',
    //             'Elderberry Tincture',
    //             'Hydrogen Peroxide',
    //             'Kaolin Clay',
    //             'Lion’s Mane',
    //             'Maca Root',
    //             'Melatonin',
    //             'Oregano Essential Oil',
    //             'Oyster Mushroom',
    //             'Parsley',
    //             'Pau D Arco',
    //             'Peppermint Essential Oil',
    //             'Piperine',
    //             'Porcini Mushroom',
    //             'Reishi Mushroom',
    //             'Sea Moss',
    //             'Shiitake Mushroom',
    //             'Shungite Elite',
    //             'Superoxide Dismutase',
    //             'Super Greens',
    //             'Wheat Grass',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/12') {
    //         setIngredients([
    //             'My High Self, Conscious Self & Basic Self Are Divinely Aligned',
    //             'I Request Light From The Highest Realms, For The Highest Good Of All Concerned',
    //             'I Request The Light From The Highest Realms of Loving, Light & Sound To Clear Karma & Negativity For The Highest Good Of All Concerned',
    //             'I See Through The Eyes Of My Soul',
    //             'I Trust All Is Well',
    //             'I Am Guided By Christ Consciousness',
    //             'I Endure With Strength & Courage',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/13') {
    //         setIngredients([
    //             'Abandonment',
    //             'Acrophobia',
    //             'Activated Charcoal',
    //             'Addictive Behaviour',
    //             'Adrenal Gland',
    //             'Agoraphobia',
    //             'Agression',
    //             'Agrimony',
    //             'Alfalfa Sprouts',
    //             'Aloe Vera',
    //             'Amethyst',
    //             'Ancestral Energy',
    //             'Anger',
    //             'Anus',
    //             'Anxiety',
    //             'Anxiety',
    //             'Apis Mellifica',
    //             'Arnica Montana',
    //             'Arthritis',
    //             'Aspen',
    //             'Autism',
    //             'Avoiding Food',
    //             'Beak',
    //             'Beech',
    //             'Belladonna',
    //             'Binge Eating',
    //             'Black Tourmaline',
    //             'Bladder',
    //             'Bladder Meridian',
    //             'Bloodstone',
    //             'Blue Quartz',
    //             'Blue Topaz',
    //             'Body Wall',
    //             'Bones',
    //             'Boredom',
    //             'Brachial Chakra',
    //             'Brain',
    //             'Calcium',
    //             'Calendula',
    //             'Cannabidiol Oil',
    //             'Carnelian',
    //             'Cecum',
    //             'Centaury',
    //             'Cerato',
    //             'Cervix',
    //             'Chamomile',
    //             'Cherry Plum',
    //             'Chestnut Bud',
    //             'Chicory',
    //             'Chlorophyll',
    //             'Chromium',
    //             'Circulatory System',
    //             'Citrine',
    //             'Clear Quartz',
    //             'Clematis',
    //             'Coconut Oil',
    //             'Colon',
    //             'Conception Meridian',
    //             'Conflict',
    //             'Constipation',
    //             'Contempt',
    //             'Coral',
    //             'Crab Apple',
    //             'Craniosacral Rhythm',
    //             'Crop',
    //             'Crown Chakra',
    //             'Crying',
    //             'Dark Green Aventurine',
    //             'Depression',
    //             'Despair',
    //             'Diabetes',
    //             'Diaphragm',
    //             'Diarrhea',
    //             'Digestive System',
    //             'Disgust',
    //             'Distress',
    //             'DMSO',
    //             'Dread',
    //             'Dynamite Products',
    //             'Ear mites',
    //             'Elm',
    //             'Embarrassed',
    //             'Emerald',
    //             'Emotional Resonance',
    //             'Emotional Trauma',
    //             'Endocrine System',
    //             'Equine Light Therapy',
    //             'Esophagus',
    //             'Excessive Appetite',
    //             'Excessive Barking',
    //             'Excessive Biting',
    //             'Excessive Fear',
    //             'Excessive Licking',
    //             'Excessive Shaking',
    //             'Fatigue',
    //             'Fear',
    //             'Fear of Being Alone',
    //             'Fear of exercise',
    //             'Fear of Inside',
    //             'Fear of Loud Sounds',
    //             'Fear of Noises',
    //             'Fear of Outside',
    //             'Fear of Small Spaces',
    //             'Fear of Sudden Movements',
    //             'Fear of Travelling in Vehicles',
    //             'Fear of Unfamiliar Objects',
    //             'Fear of Unfamiliar People or Animals',
    //             'Fear of Vet',
    //             'Fear of Wide Open Spaces',
    //             'Feathers',
    //             'Fleas',
    //             'Foot',
    //             'Forlorn',
    //             'FORLS',
    //             'Frankincense',
    //             'Fulvic Humic Acid',
    //             'Fungus',
    //             'Fur',
    //             'Gall Bladder Meridian',
    //             'Garnet',
    //             'Gentian',
    //             'Geranium',
    //             'Ginger',
    //             'Gizzard',
    //             'Gorse',
    //             'Governing Meridian',
    //             'Grief',
    //             'Grounding',
    //             'Guilt',
    //             'Hair',
    //             'Heart',
    //             'Heart Chakra',
    //             'Heart Meridian',
    //             'Heart Wall',
    //             'Heart Worm',
    //             'Heartache',
    //             'Heather',
    //             'Helplessness',
    //             'Hematite',
    //             'Hepar Sulphur',
    //             'Holly',
    //             'Homeopathic borax',
    //             'Honeysuckle',
    //             'Hoof',
    //             'Hormones',
    //             'Hornbeam',
    //             'Horror',
    //             'Hydrogen Peroxide',
    //             'Hypericum',
    //             'I am calm relaxed and comfortable',
    //             'I am courageous and resilient',
    //             'I am filled with energy and vitality',
    //             'I am healthy inside and out',
    //             'I am loved and wanted',
    //             'I am safe and protected',
    //             'I am worthy of love',
    //             'I can run and play with ease and flexibility',
    //             'I get along well with people and other animals',
    //             'I had just easily to my environment',
    //             'I have strong bones and muscles',
    //             'I release and let go of any energies that no longer serve me',
    //             'I sleep peacefully',
    //             'Immune System',
    //             'Impatiens',
    //             'Indian Pipe Tincture',
    //             'Influenza',
    //             'Inherited Energy',
    //             'Integumentary System',
    //             'Intestinal Worms',
    //             'Iodine',
    //             'It is safe to give and receive love',
    //             'Ivermectin',
    //             'Jade',
    //             'Jealous',
    //             'Joints',
    //             'Joy',
    //             'Kaolin Clay',
    //             'Kidney',
    //             'Kidney Meridian',
    //             'Lack of Control',
    //             'Lameness',
    //             'Larch',
    //             'Large Intestine Meridian',
    //             'Lavender',
    //             'Ledum',
    //             'Lethargy',
    //             'Limb',
    //             'Listlessness',
    //             'Liver',
    //             'Liver Meridian',
    //             'Loss of Appetite',
    //             'Love',
    //             'Love Unreceived',
    //             'Lung Meridian',
    //             'Lungs',
    //             'Lycopodium Clavatum',
    //             'Lyme Disease',
    //             'Lymphatic System',
    //             'Magnesium',
    //             'Magnet Therapy',
    //             'Manago Calcite',
    //             'Manuka Honey',
    //             'Massage Therapy',
    //             'Microgreens',
    //             'Milk Thistle',
    //             'Mimulus',
    //             'Mother of Pearl',
    //             'Mouth',
    //             'Muscular System',
    //             'Mustard',
    //             'My bodily organs are perfectly healthy and functioning',
    //             'Myrrh',
    //             'Myristica',
    //             'Neem Oil',
    //             'Nervous System',
    //             'Nikken Pet Pad',
    //             'No Will To Be Well',
    //             'No Will To Live',
    //             'Nux Vomica',
    //             'Oak',
    //             'Obsessive Compulsive Disorder',
    //             'Olive',
    //             'Omega 3',
    //             'Omega 6',
    //             'Omega 9',
    //             'Onyx',
    //             'Organ Wall',
    //             'Organic Beef',
    //             'Organic Chicken',
    //             'Ovary',
    //             'Overjoy',
    //             'Overwhelm',
    //             'Pancreas',
    //             'Panic',
    //             'Parasites',
    //             'Parasympathetic Nervous System',
    //             'Patchouli',
    //             'Paw',
    //             'Peace',
    //             'Penis',
    //             'Pericardium Meridian',
    //             'Peridot',
    //             'Pharynx',
    //             'Physical Trauma',
    //             'Picky Eater',
    //             'Pine',
    //             'Pink Tourmaline',
    //             'Post Traumatic Stress Disorder',
    //             'Pride',
    //             'Protective Energy Shield',
    //             'Proventriculus',
    //             'Pterotillomania',
    //             'Pumpkin',
    //             'Pylorus',
    //             'Red Chestnut',
    //             'Red Clover',
    //             'Red Light Therapy',
    //             'Rejection',
    //             'Reproductive System',
    //             'Rescue Remedy',
    //             'Resentment',
    //             'Respiratory System',
    //             'Rhodonite',
    //             'Rhus Tox',
    //             'Rock Rose',
    //             'Rock Water',
    //             'Root Chakra',
    //             'Rose Quartz',
    //             'Rosemary',
    //             'Ruta',
    //             'Sacral Chakra',
    //             'Sadness',
    //             'Salmon',
    //             'Schleranthus',
    //             'Scrotum',
    //             'Self abuse',
    //             'Sensing Chakra',
    //             'Separation Anxiety',
    //             'Shame',
    //             'Shock',
    //             'Shy',
    //             'Silicea',
    //             'Skeletal System',
    //             'Skin',
    //             'Slippery Elm',
    //             'Small Intestine',
    //             'Small Intestine Meridian',
    //             'Social Phobia',
    //             'Solar Plexus Chakra',
    //             'Spinal Cord',
    //             'Spinal Energy',
    //             'Spine',
    //             'Spleen',
    //             'Spleen Pancreas Meridian',
    //             'Star of Bethlehem',
    //             'Stomach',
    //             'Stomach Meridian',
    //             'Suspicious',
    //             'Sweet Chestnut',
    //             'Sympathetic Nervous System',
    //             'Symphitum Officinale',
    //             'Tapeworm',
    //             'Taurine',
    //             'Tea Tree Oil',
    //             'Teeth',
    //             'Testicle',
    //             'The light of love surrounds and supports me',
    //             'Throat Chakra',
    //             'Ticks',
    //             'Tiger Eye',
    //             'Tongue',
    //             'Topaz',
    //             'Toxins',
    //             'Trace Minerals',
    //             'Transmute Negative Dark Energies',
    //             'Triple Heater Meridian',
    //             'Turmeric',
    //             'Urethra',
    //             'Urinary System',
    //             'Uterus',
    //             'Vagina',
    //             'Vervain',
    //             'Vine',
    //             'Vomiting',
    //             'Vulnerable',
    //             'Walnut',
    //             'Water Violet',
    //             'White Chestnut',
    //             'Wild Oat',
    //             'Wild Rose',
    //             'Willow',
    //             'Wing',
    //             'Worms',
    //             'Worry',
    //             'Worthlessness',
    //             'YL Animal Scents',
    //             'YL Chamomile',
    //             'YL Frankincense',
    //             'YL Geranium',
    //             'YL Ginger',
    //             'YL Infectaway',
    //             'YL Lavender',
    //             'YL Lavender',
    //             'YL Mendwell',
    //             'YL ParaGize',
    //             'YL Patchouli',
    //             'YL Peace and Calming',
    //             'YL Puriclean',
    //             'YL Rosemary',
    //             'YL Stress Away',
    //             'YL T Away',
    //             'YL Valor',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/14') {
    //         setIngredients([
    //             'I Am Divinely Blessed',
    //             'I Am Divinely centered and focused',
    //             'I Am Divinely supported',
    //             'I Am Grateful for my life',
    //             'I Am Divine Joy',
    //             'I Am Divine Peace',
    //             'I Am Divine Truth',
    //             'I invoke Divine Love & Light always in All ways',
    //             'I Am a clear channel for the Divine',
    //             'I Am Divinely content and blessed',
    //             'I acknowledge Divine beauty in everything',
    //             'I acknowledge everything is for my highest good',
    //             'I allow myself to receive Divine knowledge and purpose',
    //             'I honor the Divine in All sentient beings',
    //             'I honor the Divine in everything and everyone',
    //             'I Am open to hearing and feeling the Divine',
    //             'I appreciate Divine intervention for the highest good of all',
    //             'I appreciate joyful and loving people in my life',
    //             'I appreciate time with my Soul',
    //             'I attract joyful and loving people',
    //             'I celebrate in forgiving and accepting all that is',
    //             'I choose to live in Divine harmony with life as it is',
    //             'I create time for more joy in my life',
    //             'I create and dedicate time for my spiritual practices',
    //             'I invoke emanate Christ consciousness',
    //             'I invoke and emanate Divine Love and purpose',
    //             'I emanate Divine orchestrated will',
    //             'I emanate Divine Joy',
    //             'I emanate Divine contentment and balance',
    //             'I forgive and release myself and others from resentment',
    //             'I ground myself in Divine Faith',
    //             'I invoke ease and grace in all I do',
    //             'I joyfully serve for the highest good of all concerned',
    //             'I know I Am Divinely blessed',
    //             'I know I Am Divinely supported',
    //             'I manifest Divine abundance',
    //             'I manifest Divine qualities',
    //             'I open myself to Divine knowledge',
    //             'I raise my vibration to emulate the Divine',
    //             'I receive Divine Love',
    //             'I see the best in myself and others',
    //             'I transcend the physical and the ego',
    //             'I trust in the process of life',
    //             'Infinite Love & Gratitude',
    //             'My consciousness is pure and of good intent',
    //             'My ego serves my soul purpose for the highest good of all concerned',
    //             'My high self and conscious self and basic self are Divinely aligned',
    //             'My will and my spirit are Divinely aligned',
    //             'Monatomic Gold',
    //             'Etherium Gold',
    //             'Rose Quartz',
    //             'Crystal Quartz',
    //             'Back Flower Rescue Remedy',
    //             'Young Living Oil Brain Power',
    //             'Young Living Oil Christmas Spirit',
    //             'Young Living Oil Frankincense',
    //             'Young Living Oil Higher Purpose',
    //             'Young Living Oil Higher Unity',
    //             'Young Living Oil Joy',
    //             'Young Living Oil Myrrh',
    //             'Young Living Oil Peace and Calming',
    //             'Young Living Oil Valor',
    //             'Young Living Oil White Angelica',
    //         ]);
    //     }
    // }, [pathname]);
    // useEffect(() => {
    //     if (pathname === '/harmonizing-screen/15') {
    //         setIngredients([
    //             '432 Hz',
    //             '528 Hz',
    //             '800 Hz',
    //             'Aconite',
    //             'Agarikon',
    //             'All Schumann Resonance Frequencies',
    //             'Aloe Vera',
    //             'Arnica Montana',
    //             'Açaí',
    //             'Barberry',
    //             'Basil',
    //             'Belladonna',
    //             'Black Truffle',
    //             'Black Trumpet',
    //             'Black Walnut',
    //             'Bladderwrack',
    //             'Blue for Communication',
    //             'Blue Green Algae',
    //             'Blueberries',
    //             'Bryonia',
    //             'Calc Carb',
    //             'Carbo Veg',
    //             'Catnip',
    //             'CBD',
    //             'Chaga',
    //             'Chamomilla',
    //             'Chlorella',
    //             'Cilantro',
    //             'Cinnamon',
    //             'Cinnamonum',
    //             'Cordyceps',
    //             'Coriolus Versicolor',
    //             'Curcumin',
    //             'Echinacea',
    //             'Elderberries',
    //             'Elderberry',
    //             'Eucalyptus',
    //             'Fenugreek',
    //             'Ferrum Phosphoricum',
    //             'Frankincense',
    //             'Fresh Birch Polypore',
    //             'Fulvic Humic Acid',
    //             'Gallium',
    //             'Garlic',
    //             'Gelsemium',
    //             'Ginger',
    //             'Ginseng',
    //             'Green for heart health and Love',
    //             'Gymnestra Sylvestre',
    //             'Harmonized',
    //             'Hepar Sulph',
    //             'Hydrastis Canadensis',
    //             'Hydrogen',
    //             'I Am Happy and Healthy and Loved and Blessed',
    //             'I maintain a strong Life Force',
    //             'Invoke Divine Support',
    //             'Kali Bich',
    //             'Kali Muriaticum',
    //             'Lions Maine',
    //             'Maitake',
    //             'Mercurius Sol',
    //             'Milk Thistle',
    //             'Monotomic Gold',
    //             'Moringa',
    //             'My Life Force is strong and vibrant',
    //             'Nicotinase',
    //             'Noni fruit',
    //             'Nux Vomica',
    //             'Olive Leaf Extract',
    //             'Orange for creativity',
    //             'Oregano',
    //             'Organic Teas',
    //             'Oscillococcinum',
    //             'Ozone',
    //             'Pepper',
    //             'Poriacocos',
    //             'Pulsatilla',
    //             'Quercetin',
    //             'RC',
    //             'Reishi',
    //             'Rescue Remedy',
    //             'Rhus Tox',
    //             'Sage',
    //             'Serrapeptase',
    //             'Shiitake',
    //             'Spirulina',
    //             'Sulphur',
    //             'Turkey Tail',
    //             'Turmeric',
    //             'Valor',
    //             'Wheat grass',
    //             'Yellow for digestion and Joy and self worth',
    //         ]);
    //     }
    // }, [pathname]);
    const Context = createContext(null);
// video load fix   
    const [frontMediaFile, setFrontMediaFile] = useState(mediaFile?.blob || mediaFile?.uri);
    useEffect(() => {
        setFrontMediaFile(mediaFile?.blob || mediaFile?.uri);
    }, [mediaFile])
    useEffect(() => {
        const handleAudioError = (e: any) => {
            console.error('Error with audio playback:', e);
        };

        if (frontMediaFile) {
            const audioElement = new Audio(frontMediaFile);
            audioElement.addEventListener('error', handleAudioError);

            return () => {
                audioElement.removeEventListener('error', handleAudioError);
            };
        }
        return frontMediaFile;
    }, [frontMediaFile]);
    return (
        <>
            {/* <button type="button" onClick={() => { handleMediaPlay() }}>Play</button> */}
            <ReactPlayer
                style={{ position: 'absolute' }}
                id="player"
                loop
                width="100%"
                height="100%"
                // video load fix    
                // url={mediaFile.uri}
                url={frontMediaFile}
                playsinline
                playing={playing}
                onPlay={() => {
                    // eslint-disable-next-line no-console
                    console.log('fractal on play..');
                    handleMediaPlay();
                }}
                onReady={() => onReady()}
                onPause={() => {
                    // eslint-disable-next-line no-console
                    console.log('fractal paused ...');
                    handleMediaPause();
                }}
                onError={(e) => {
                // video load fix    
                    setFrontMediaFile(mediaFile?.uri);
                    handleError(e)
                }}
            />
            {playing ? <Center ingredients={ingredients} /> : null}
        </>
    );
};
export default FractalMedia;
