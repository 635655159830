import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'idb-keyval';
import { GlobalStateType } from '../../redux/rootTypes';
import { BACKGROUND_NAME, MediaType, MEDIA_INDEXED_KEY } from '../../redux/medias/types';

export const useMediaFractal = () => {
    const medias = useSelector((state: GlobalStateType) => state.medias.data);
    const [mediaFile, setMediaFile] = useState<MediaType | null>(null);

    const loadMedia = async () => {
        try {
            const indexedMedias = (await get(MEDIA_INDEXED_KEY)) || [];
            if (indexedMedias) {
                console.log('indexedMedias', indexedMedias);
                const filteredWithoutBacground = indexedMedias.filter(
                    (m: MediaType) => m.key?.indexOf(BACKGROUND_NAME) === -1,
                );
                // const index = Math.floor(Math.random() * (filteredWithoutBacground.length - 0)) + 0;
                console.log('filteredWithoutBacground', filteredWithoutBacground)
                // const tempMediaFile = filteredWithoutBacground[index]; //disabling random video display show only first video
                // video load fix
                if (filteredWithoutBacground?.length) {
                    const tempMediaFile = filteredWithoutBacground[0];
                    if (tempMediaFile) {
                        setMediaFile({
                            ...tempMediaFile,
                            blob:
                                (tempMediaFile?.blob && URL.createObjectURL(tempMediaFile.blob)),
                            // uri:
                            //     (tempMediaFile?.blob && URL.createObjectURL(tempMediaFile.blob)) ||
                            //     tempMediaFile?.uri,
                            playing: true,
                        });
                    }
                }
                // const tempMediaFile = filteredWithoutBacground[0];
                // if (tempMediaFile)
                //     setMediaFile({
                //         ...tempMediaFile,
                //         uri:
                //             (tempMediaFile?.blob && URL.createObjectURL(tempMediaFile.blob)) ||
                //             tempMediaFile?.uri,
                //         playing: true,
                //     });
                console.log('mediaFile useMediaFractal', mediaFile)
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error', error);
        }
    };

    useEffect(() => {
        if (!mediaFile) loadMedia();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medias]);

    return mediaFile;
};

export const useMediaBackground = () => {
    const medias = useSelector((state: GlobalStateType) => state.medias.data);
    const [backgroundMedia, setBackgroundMedia] = useState<MediaType | null>(null);

    const loadMedia = async () => {
        try {
            const indexedMedias = (await get(MEDIA_INDEXED_KEY)) || [];
            if (indexedMedias.length > 0) {
                const tempBackground = indexedMedias?.find(
                    (m: MediaType) => m.key && m.key.indexOf(BACKGROUND_NAME) > -1,
                );
                if (tempBackground)
                    setBackgroundMedia({
                        ...tempBackground,
                        // video load fix
                        blob:
                            (tempBackground?.blob && URL.createObjectURL(tempBackground?.blob)),
                        // uri:
                        // (tempBackground?.blob && URL.createObjectURL(tempBackground.blob)) ||
                        // tempBackground.uri,
                        playing: true,
                    });
                console.log('backgroundMedia useMediaFractal', backgroundMedia)
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    useEffect(() => {
        // console.log(backgroundMedia);
        if (!backgroundMedia) loadMedia();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medias]);

    return backgroundMedia;
};
