import { Grid, Snackbar } from '@material-ui/core';
import React, { ReactNode, useEffect } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import Downloader from '../../components/Downloader';
import Header from '../../components/Header/index';
import { GlobalStateType } from '../../redux/rootTypes';

interface Props {
    children: ReactNode;
}

const MainLayout = ({ children }: Props) => {
    const { message, severity } = useSelector((state: GlobalStateType) => state.notifications);
    const [showNotification, setShowNotification] = React.useState(false);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowNotification(false);
    };

    function Alert(alertProps: AlertProps) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <MuiAlert elevation={6} variant="filled" {...alertProps} />;
    }

    useEffect(() => {
        setShowNotification(!!message);
    }, [message]);

    return (
        <>
            <Snackbar
                open={showNotification}
                key="topcenter"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container direction="column">
                <Grid>
                    <Header />
                </Grid>
                <Grid container justify="center">
                    {children}
                </Grid>
            </Grid>
            <Downloader />
        </>
    );
};

export default MainLayout;
