import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useTimer } from 'react-compound-timer';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import HARMONIZE_TIME_IN_SECONDS from '../../constants/harmonizer';
import { GlobalStateType } from '../../redux/rootTypes';
import { resetHarmonizeTime } from '../../redux/panels/actions';
import { toggleHarmonizing } from '../../redux/app/actions';

interface BackgroundComponenteType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mediaFile: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaReady: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPause: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPlay: any;
    playing: boolean;
}

const useStyle = makeStyles<Theme>(() => ({
    timer: {
        color: 'white',
        marginTop: '-5px',
        fontSize: 14,
        fontFamily: ['Poppins', 'regular'].join(','),
    },
}));

const formatTimerValue = (value: number) => {
    return `${value < 10 ? `0${value}` : value}`;
};
const VideoPlayer = () => {
    const [stopVideo, setStopVideo] = useState(false);
    console.log('okok');
};

const HarmonizeCounter = ({
    handleError,
    mediaFile,
    playing,
    handleMediaReady,
    handleMediaPause,
    handleMediaPlay,
}: BackgroundComponenteType) => {
    const classes = useStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const harmonizing = useSelector((state: GlobalStateType) => state.header.harmonizing);
    const harmonizingTime = useSelector((state: GlobalStateType) => state.panels.harmonizeTime);
    // console.log('tttttttttt*******', harmonizingTime);
    const {
        value,
        controls: { start, pause, setTime }, // Add setTime
    } = useTimer({
        initialTime: harmonizingTime,
        formatValue: formatTimerValue,
        lastUnit: 'm',
        direction: 'backward',
        startImmediately: false,
        // onStop: () => console.error("onExpire called"),
        // checkpoints : [
        //     {
        //         time: 0,
        //         callback: () => { 
        //         dispatch(toggleHarmonizing(false));          
        //         // dispatch(stopHarmonizing(true));          
        //         console.error("checkpoints called") ;
        //         },
        //     }
        // ]
    });
    // const ks = useSelector((state: GlobalStateType) => state.header);
    // console.log(ks);

    useEffect(() => {
        if (harmonizing) start();
        else pause();
    }, [harmonizing, start, pause]);
    const handleHarmonizeAgain = () => {
        // Reset time to infinity
        // setTime({ time: Infinity, running: false });
        dispatch(resetHarmonizeTime());
        start();
    };
    // if (value.state === 'STOPPED') handleMediaPause();
    // if (value.state === 'STOPPED') history.push('/harmonized-complete');
    const location = useLocation();
    // console.log('location', location)
    // const [kss, setKss] = useState(null);
    const pathHash = location.hash.split('#');
    // console.log('Latitude:', pathHash.length);

    const pathSegments = location.pathname.split('/');
    // console.log('Latitude:', pathSegments[pathSegments.length - 1]);
    if (value.state === 'STOPPED') {
        history.push(`/harmonized-complete#${pathSegments[pathSegments.length - 1]}`);
    }
    // if (pathHash.length === 1 && value.state === 'STOPPED')
    //     history.push(`/harmonized-complete#${pathSegments[pathSegments.length - 1]}`);
    return (
        <Typography className={classes.timer} variant="body1" color="initial" align="right">
            {formatTimerValue(value.m)}:{formatTimerValue(value.s)}
        </Typography>
    );
};

export default HarmonizeCounter;
