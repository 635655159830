import { API, Storage } from 'aws-amplify';
import { get, set } from 'idb-keyval';
import axios from 'axios';
import { eventChannel, END } from 'redux-saga';
import awsexports from '../../aws-exports';
import { MediaType, MEDIA_INDEXED_KEY } from './types';

API.configure(awsexports);
Storage.configure(awsexports);
// const localURL = process.env.REACT_APP_AWS_COGNITO_USER_POOL_SIGN_OUT_URLS || 'http://localhost:3000/';
// const localURL = window.location.origin;
const localURL = 'https://webappsitesdemo.com';

export const getSignedURL = async (payload: MediaType): Promise<MediaType | null> => {
    let localUri = '';
    let localMedia: MediaType;
    if (payload.key) {
        // video load fix    
        localUri = `${localURL}/${payload?.key}` ;
        return (
            // Storage.get(payload.key, { level: 'public', expires: 100000 })
            //     // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            //     .then((uri: any) => {
            //         localUri = uri;
            //         return get(MEDIA_INDEXED_KEY);
            //     })

                get(MEDIA_INDEXED_KEY)
                .then((indexedMedias: MediaType[] = []) => {
                    localMedia = {
                        key: payload.key,
                        ...(indexedMedias.find((m: MediaType) => m.key === payload.key) || {}),
                        uri: localUri,
                    };
                    const filtered =
                        indexedMedias.filter((m: MediaType) => m.key !== payload?.key) || [];
                    return set(MEDIA_INDEXED_KEY, [...filtered, localMedia]);
                })
                .then(() => {
                    return localMedia;
                })
                .catch((e) => {
                    // eslint-disable-next-line no-console
                    console.info('err', e);
                    return null;
                })
        );
    }
    return null;
};

export const getMedia = async (media: MediaType) => {
    const signedMedia = await getSignedURL(media);
    return eventChannel((emitter) => {
        let counter = 0;
        const onProgress = (progressEvent: ProgressEvent) => {
            if (progressEvent.lengthComputable) {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                );
                if (percentCompleted > counter) {
                    counter += 5;
                    emitter({
                        ...signedMedia,
                        progress: percentCompleted || 1,
                        isDownloading: true,
                    });
                }
            }
        };

        const onFailure = (e: ProgressEvent) => {
            console.log("e", e)
            emitter({ error: e });
            emitter(END);
        };

        let blob: Blob;
        axios({
            url: signedMedia?.uri,
            method: 'GET',
            // video load fix    
            // responseType: 'arraybuffer',
            responseType: 'blob',
            onDownloadProgress: onProgress,
        })
            .then((response) => {
                // video load fix   
                // console.warn("response", response)
                // try {
                //     blob = new Blob([response.data], { type: response?.headers['content-type'] });
                // } catch (e) {
                //     console.log('blob', e)
                // }
                blob = response.data;
                return get(MEDIA_INDEXED_KEY);
            })
            .then((medias) => {
                console.log("medias", medias)
                const filtered = medias?.filter((m: MediaType) => m.key !== signedMedia?.key) || [];
                return set(MEDIA_INDEXED_KEY, [
                    ...filtered,
                    {
                        ...signedMedia,
                        blob,
                        progress: 100,
                        isDownloading: false,
                    },
                ]);
            })
            .then(() => {
                emitter({ ...signedMedia, progress: 100, isDownloading: false });
                return emitter(END);
            })
            .catch(onFailure);

        return async () => {
            // eslint-disable-next-line  no-console
            emitter({ ...signedMedia, isDownloading: false });
            console.log('Finish...');
            return emitter(END);
        };
    });
};

export const listMedias = async (): Promise<MediaType[]> => {
    const medias = (await Storage.list('medias/', { level: 'public' })) || [];
    console.log("medias", medias)
    return (
        medias
            ?.filter((m: { size: number }) => m.size > 0)
            .map(({ key }: { key: string }) => ({ key })) || []
    );
};
