import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';

export default () => {
    const {
        user: { code, subscription, payment },
    } = useSelector((state: GlobalStateType) => state);

    const validateBilling = () => {
        if (code) return code?.status === 'active';

        if (subscription && subscription?.subscriptionStatus)
            return (
                subscription?.subscriptionStatus === 'active' ||
                subscription?.subscriptionStatus === 'trialing' ||
                subscription?.subscriptionStatus === 'pending'
            );

        if (payment && payment?.paymentIntents) {
            const onePayment = payment.paymentIntents.filter((p) => p.status === 'succeeded');
            return onePayment.length > 0;
        }

        return false;
    };

    const [isValid, setIsValid] = useState(validateBilling());

    // currently disabling in-app purchases feature
    useEffect(() => {
        setIsValid(validateBilling());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, subscription, payment]);
    return isValid;
    // return true;
};
